<template>
	<div>
		<div
			class="text-left text-h5"
			style="font-weight: 500; font-size: 22px; color: rgb(var(--v-theme-primary))"
		>Sentry activity</div>
		<Line id="sentry-chart" :options="chartOptions" :data="chartData" />
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { Line } from 'vue-chartjs';

	export default defineComponent({
		name: 'SentryChart',

		props: ['campaignStats'],

		components: { Line },

		data: function () {
			return {};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {},

		computed: {
			chartData() {
				let result = {};
				result.labels = this.campaignStats.sentryStats.map((l) => l.date);
				result.datasets = [];
				result.datasets.push({
					label: 'Sentry replacements',
					data: this.campaignStats.sentryStats.map((l) => l.replaced),
					backgroundColor: 'rgba(255, 137, 69,1)',
					type: 'bar',
					stack: 'combined',
				});

				return result;
			},

			chartOptions() {
				let data = this.campaignStats.sentryStats.map((l) => l.replaced);
				let maxValue = Math.max(... data);
				let maxValuePlusBuffer = maxValue + 1;

				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						title: {
							display: false,
						},
					},
					scales: {
						y: {
							stacked: false,
							ticks: {
								display: true,
							},
							max: maxValuePlusBuffer
						},
						x: {
							ticks: {
								autoSkip: true,
								maxTicksLimit: 12,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				};
			},
		},
	});
</script>

<style scoped lang="scss"></style>
