import HttpClient from "@/services/HttpClient";

export default class OrganizationService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getOrganization() {
        return await this.client.get('');

    }

   async updateOrganization(organization) {
        return await this.client.put('',organization);
    }

    async getBillingProfile() {
        let result =  await this.client.get('/billingProfile');
        return result.data;
    }

    async getCommissionAgents() {
        let result =  await this.client.get('/commissionAgents');
        return result.data;
    }

    async updateBillingProfile(billingProfile) {
        let result =  await this.client.put('/billingProfile',billingProfile);
        return result.data;
    }

    async getBillableUsage(month,year) {
        let result =  await this.client.get('/billableUsage',{
            params: {
                month: month,
                year: year
            }});
        return result.data;
    }

    async addAdjustment(adjustment) {
        let result =  await this.client.post('/billableUsage/adjustment',adjustment);
        return result.data;
    }

    async deleteAdjustment(id) {
        let result =  await this.client.delete(`/billableUsage/adjustment/${id}`);
        return result.data;
    }
}