<template>
	<div>
		<div
			class="text-left text-h5"
			style="font-weight: 500; font-size: 22px; color: rgb(var(--v-theme-primary))"
		>List activity</div>
		<Line
					id="lead-chart"
					:options="chartOptions"
					:data="chartData"
		/>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import { Line } from 'vue-chartjs';

export default defineComponent({
	name: 'LeadChart',

	props: ['campaignStats'],

	components: {Line},

	data: function () {
		return {};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {},

	computed: {
		chartData() {
			let result = {};
			result.labels = this.campaignStats.listStats.map(l => l.date);
			result.datasets = [];

			result.datasets.push({
				label: 'Total supplied',
				data: this.campaignStats.listStats.map(l => l.total),
				borderColor: 'rgba(20, 20, 43,1)',
				backgroundColor: 'rgba(20, 20, 43,1)',
				stack: 'combined',
				lineTension: 0.4,
				radius: 3
			})
			result.datasets.push({
				label: 'Inactive',
				data: this.campaignStats.listStats.map(l => l.inactive),
				borderColor: 'rgba(217,50,26,1)',
				backgroundColor: 'rgba(217,50,26,1)',
				stack: 'combined',
				lineTension: 0.4,
				radius: 3
			})
			result.datasets.push({
				label: 'Sent to dialer',
				data: this.campaignStats.listStats.map(l => l.valid),
				backgroundColor: 'rgba(106,190,40,0.7)',
				type: 'bar',
				stack: 'combined',
			})

			return result;
		},

		chartOptions() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					title: {
						display: false,
						text: 'List activity'
					}
				},
				scales: {
					y: {
						stacked: false,
						ticks: {
							display: true
						}
					},
					x: {
						ticks: {
							autoSkip: true,
							maxTicksLimit: 12,
							maxRotation: 0,
							minRotation: 0,
						}
					}
				}
			}
		},
	},
});
</script>

<style scoped lang="scss"></style>
