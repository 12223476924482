<template>
	<div>
		<div
			class="text-left text-h5"
			style="font-weight: 500; font-size: 22px; color: rgb(var(--v-theme-primary))"
		>SMS activity</div>
		<Line id="sms-chart" :options="chartOptions" :data="chartData" />
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { Line } from 'vue-chartjs';

	export default defineComponent({
		name: 'SmsChart',

		props: ['campaignStats'],

		components: { Line },

		data: function () {
			return {};
		},

		mounted() {
			console.log(this.campaignStats)
		},

		beforeUnmount() {},

		methods: {},

		computed: {
			chartData() {
				console.log(this.campaignStats);
				let result = {};
				result.labels = this.campaignStats.smsStats.map((l) => l.date);
				result.datasets = [];

				result.datasets.push({
					label: 'Sent',
					data: this.campaignStats.smsStats.map((l) => l.sent),
					borderColor: 'rgba(20, 20, 43,1)',
					backgroundColor: 'rgba(20, 20, 43,1)',
					stack: 'combined',
					lineTension: 0.4,
					radius: 3,
				});
				result.datasets.push({
					label: 'Failed',
					data: this.campaignStats.smsStats.map((l) => l.failed),
					borderColor: 'rgba(217,50,26,1)',
					backgroundColor: 'rgba(217,50,26,1)',
					stack: 'combined',
					lineTension: 0.4,
					radius: 3,
				});
				result.datasets.push({
					label: 'Delivered',
					data: this.campaignStats.smsStats.map((l) => l.delivered),
					backgroundColor: 'rgba(106,190,40,0.7)',
					type: 'bar',
					stack: 'combined',
				});

				return result;
			},

			chartOptions() {
				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						title: {
							display: false,
							text: 'SMS activity',
						},
					},
					scales: {
						y: {
							stacked: false,
							ticks: {
								display: true,
							},
						},
						x: {
							ticks: {
								autoSkip: true,
								maxTicksLimit: 12,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				};
			},
		},
	});
</script>

<style scoped lang="scss"></style>
