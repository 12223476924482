<template>
	<div>
		<div class="py-2">
			<v-container fluid class="pa-0 ma-0">
				<v-row v-for="commission in commissions" :key="commission.id" dense>
					<v-col cols="4">
						<v-autocomplete
							density="compact"
							variant="outlined"
							hide-details
							:items="agentList"
							v-model="commission.commissionAgentId"
							item-value="id"
							item-title="accountingName"
							label="Agent"
						></v-autocomplete>
					</v-col>
					<v-col cols="2">
						<v-text-field
							number
							hide-details
							variant="outlined"
							density="compact"
							label="Rate"
							persistent-placeholder
							suffix="%"
							v-model="commission.rate"
							oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field
							number
							hide-details
							variant="outlined"
							density="compact"
							label="Payable to"
							persistent-placeholder
							v-model="commission.payableTo"
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field
							number
							hide-details
							variant="outlined"
							density="compact"
							label="Note"
							persistent-placeholder
							v-model="commission.note"
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<div class="row-format align-center gap-3">
						<date-selector v-model="commission.expiration" :standard="true" label="Expiration"></date-selector>
						<v-icon class="ml-auto" @click="deleteCommission(commission)">delete</v-icon></div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div class="row-format">
			<v-btn @click="addNewCommission" class="primary-action">Add new commission</v-btn>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import OrganizationService from '@/modules/organization/OrganizationService';
	import { v4 as uuid } from 'uuid';
	import DateSelector from '@/components/DateSelector';

	export default defineComponent({
		name: 'CommissionAgents',

		props: ['modelValue'],

		components: { DateSelector },

		data: function () {
			return {
				organizationService: new OrganizationService(),
				agentList: [],
				commissions: this.modelValue,
			};
		},

		mounted() {
			this.getAgentList();
		},

		beforeUnmount() {},

		methods: {
			async getAgentList() {
				let result = await this.organizationService.getCommissionAgents();
				this.agentList.splice(0, this.agentList.length);
				this.agentList.push(...result);
				this.agentList.sort((a,b) => a.accountingName.localeCompare(b.accountingName));
			},

			addNewCommission: function () {
				this.commissions.push({
					id: uuid(),
					commissionAgentId: null,
					rate: 0,
					payableTo: null,
					note: null,
					expiration: null,
				});
			},

			deleteCommission: function(commission){
				let ix = this.commissions.findIndex(c => c.id === commission.id);
				this.commissions.splice(ix,1);
			}
		},

		watch: {
			commissions: {
				deep: true,
				handler: function () {
					this.$emit('update:modelValue', this.commissions);
				},
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
